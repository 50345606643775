import { default as billingN0AOBQHWCyMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/account/index/billing.vue?macro=true";
import { default as companyYnBpmMACYCMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/account/index/company.vue?macro=true";
import { default as planaRLRBqLH85Meta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/account/index/plan.vue?macro=true";
import { default as indexySeTQebKjNMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/account/index.vue?macro=true";
import { default as callbackRePMVzLLlzMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/auth/callback.vue?macro=true";
import { default as _91token_93it0SsRrk6MMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/auth/reset-password/[token].vue?macro=true";
import { default as indexxH1ZVeZFhGMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/auth/reset-password/index.vue?macro=true";
import { default as indexg32HIX5ra4Meta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/candidates/[idcandidate]/index.vue?macro=true";
import { default as best_45by_45role1kO7Md9yqxMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/candidates/best-by-role.vue?macro=true";
import { default as indexHkombkBNhJMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/candidates/index.vue?macro=true";
import { default as scheduledZCw80PKErLMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/demo/scheduled.vue?macro=true";
import { default as indexaQdf1WgH4HMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/index.vue?macro=true";
import { default as loginpj5xt0ZRWKMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/login.vue?macro=true";
import { default as logoutGyeEpIdKreMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/logout.vue?macro=true";
import { default as indexQO4kRNhqRNMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/offer/create/index.vue?macro=true";
import { default as create05ezev9FMQMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/offer/create.vue?macro=true";
import { default as importwGVHoswSDmMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/offer/import.vue?macro=true";
import { default as detailsLwJFOK3dcTMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/edit/details.vue?macro=true";
import { default as index0lFww5dwhmMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/edit/index.vue?macro=true";
import { default as technical_45requirementsPkJIPi05sfMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/edit/technical-requirements.vue?macro=true";
import { default as editenfCEboW2YMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/edit.vue?macro=true";
import { default as detailsjWjiWuZbL8Meta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/index/details.vue?macro=true";
import { default as discardedsNnq70wKmVMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/index/discarded.vue?macro=true";
import { default as indexgtZxk2BZ6bMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/index/index.vue?macro=true";
import { default as pipelinefLzkVvHHRIMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/index/pipeline.vue?macro=true";
import { default as searchYPGYhnDb0bMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/index/search.vue?macro=true";
import { default as indexvTuoGVg6WKMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/index.vue?macro=true";
import { default as password_45resetSYBE9bXPO1Meta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/password-reset.vue?macro=true";
import { default as indexHntcHSXaxJMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/register/index.vue?macro=true";
import { default as password_45setupl6oVvyzqVeMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/register/password-setup.vue?macro=true";
import { default as profile_45setupFNZFzPeHLDMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/register/profile-setup.vue?macro=true";
import { default as buyPmeshfxvzZMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/subscription/buy.vue?macro=true";
import { default as payment_45methodRv7yUzeyw4Meta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/subscription/payment-method.vue?macro=true";
import { default as successfulYs2ePnf4NNMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/subscription/successful.vue?macro=true";
import { default as updateDR8t5RqW1KMeta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/subscription/update.vue?macro=true";
import { default as indexAtPU8PnmW3Meta } from "/codebuild/output/src3517669114/src/frontend-hire/pages/users/index.vue?macro=true";
import { default as component_45stubTOMzBdaWN8Meta } from "/codebuild/output/src3517669114/src/frontend-hire/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__ofnxr56xffz7kcza5pus2qn4u4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubTOMzBdaWN8 } from "/codebuild/output/src3517669114/src/frontend-hire/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__ofnxr56xffz7kcza5pus2qn4u4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account___es",
    path: "/account",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/account/index.vue"),
    children: [
  {
    name: "account-index-billing___es",
    path: "billing",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/account/index/billing.vue")
  },
  {
    name: "account-index-company___es",
    path: "company",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/account/index/company.vue")
  },
  {
    name: "account-index-plan___es",
    path: "plan",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/account/index/plan.vue")
  }
]
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/account/index.vue"),
    children: [
  {
    name: "account-index-billing___en",
    path: "billing",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/account/index/billing.vue")
  },
  {
    name: "account-index-company___en",
    path: "company",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/account/index/company.vue")
  },
  {
    name: "account-index-plan___en",
    path: "plan",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/account/index/plan.vue")
  }
]
  },
  {
    name: "auth-callback___es",
    path: "/auth/callback",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/auth/callback.vue")
  },
  {
    name: "auth-callback___en",
    path: "/en/auth/callback",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/auth/callback.vue")
  },
  {
    name: "auth-reset-password-token___es",
    path: "/auth/reset-password/:token()",
    meta: _91token_93it0SsRrk6MMeta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/auth/reset-password/[token].vue")
  },
  {
    name: "auth-reset-password-token___en",
    path: "/en/auth/reset-password/:token()",
    meta: _91token_93it0SsRrk6MMeta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/auth/reset-password/[token].vue")
  },
  {
    name: "auth-reset-password___es",
    path: "/auth/reset-password",
    meta: indexxH1ZVeZFhGMeta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/auth/reset-password/index.vue")
  },
  {
    name: "auth-reset-password___en",
    path: "/en/auth/reset-password",
    meta: indexxH1ZVeZFhGMeta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/auth/reset-password/index.vue")
  },
  {
    name: "candidates-idcandidate___es",
    path: "/candidates/:idcandidate()",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/candidates/[idcandidate]/index.vue")
  },
  {
    name: "candidates-idcandidate___en",
    path: "/en/candidates/:idcandidate()",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/candidates/[idcandidate]/index.vue")
  },
  {
    name: "candidates-best-by-role___es",
    path: "/candidates/best-by-role",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/candidates/best-by-role.vue")
  },
  {
    name: "candidates-best-by-role___en",
    path: "/en/candidates/best-by-role",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/candidates/best-by-role.vue")
  },
  {
    name: "candidates___es",
    path: "/candidates",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/candidates/index.vue")
  },
  {
    name: "candidates___en",
    path: "/en/candidates",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/candidates/index.vue")
  },
  {
    name: "demo-scheduled___es",
    path: "/demo/scheduled",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/demo/scheduled.vue")
  },
  {
    name: "demo-scheduled___en",
    path: "/en/demo/scheduled",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/demo/scheduled.vue")
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/index.vue")
  },
  {
    name: "login___es",
    path: "/login",
    meta: loginpj5xt0ZRWKMeta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/login.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: loginpj5xt0ZRWKMeta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/login.vue")
  },
  {
    name: "logout___es",
    path: "/logout",
    meta: logoutGyeEpIdKreMeta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/logout.vue")
  },
  {
    name: "logout___en",
    path: "/en/logout",
    meta: logoutGyeEpIdKreMeta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/logout.vue")
  },
  {
    name: create05ezev9FMQMeta?.name,
    path: "/offer/create",
    meta: create05ezev9FMQMeta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offer/create.vue"),
    children: [
  {
    name: "offer-create___es",
    path: "",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offer/create/index.vue")
  }
]
  },
  {
    name: create05ezev9FMQMeta?.name,
    path: "/en/offer/create",
    meta: create05ezev9FMQMeta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offer/create.vue"),
    children: [
  {
    name: "offer-create___en",
    path: "",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offer/create/index.vue")
  }
]
  },
  {
    name: "offer-import___es",
    path: "/offer/import",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offer/import.vue")
  },
  {
    name: "offer-import___en",
    path: "/en/offer/import",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offer/import.vue")
  },
  {
    name: editenfCEboW2YMeta?.name,
    path: "/offers/:idoffer()/edit",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/edit.vue"),
    children: [
  {
    name: "offers-idoffer-edit-details___es",
    path: "details",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/edit/details.vue")
  },
  {
    name: "offers-idoffer-edit___es",
    path: "",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/edit/index.vue")
  },
  {
    name: "offers-idoffer-edit-technical-requirements___es",
    path: "technical-requirements",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/edit/technical-requirements.vue")
  }
]
  },
  {
    name: editenfCEboW2YMeta?.name,
    path: "/en/offers/:idoffer()/edit",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/edit.vue"),
    children: [
  {
    name: "offers-idoffer-edit-details___en",
    path: "details",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/edit/details.vue")
  },
  {
    name: "offers-idoffer-edit___en",
    path: "",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/edit/index.vue")
  },
  {
    name: "offers-idoffer-edit-technical-requirements___en",
    path: "technical-requirements",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/edit/technical-requirements.vue")
  }
]
  },
  {
    name: indexvTuoGVg6WKMeta?.name,
    path: "/offers/:idoffer()",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/index.vue"),
    children: [
  {
    name: "offers-idoffer-index-details___es",
    path: "details",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/index/details.vue")
  },
  {
    name: "offers-idoffer-index-discarded___es",
    path: "discarded",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/index/discarded.vue")
  },
  {
    name: "offers-idoffer-index___es",
    path: "",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/index/index.vue")
  },
  {
    name: "offers-idoffer-index-pipeline___es",
    path: "pipeline",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/index/pipeline.vue")
  },
  {
    name: "offers-idoffer-index-search___es",
    path: "search",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/index/search.vue")
  }
]
  },
  {
    name: indexvTuoGVg6WKMeta?.name,
    path: "/en/offers/:idoffer()",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/index.vue"),
    children: [
  {
    name: "offers-idoffer-index-details___en",
    path: "details",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/index/details.vue")
  },
  {
    name: "offers-idoffer-index-discarded___en",
    path: "discarded",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/index/discarded.vue")
  },
  {
    name: "offers-idoffer-index___en",
    path: "",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/index/index.vue")
  },
  {
    name: "offers-idoffer-index-pipeline___en",
    path: "pipeline",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/index/pipeline.vue")
  },
  {
    name: "offers-idoffer-index-search___en",
    path: "search",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/offers/[idoffer]/index/search.vue")
  }
]
  },
  {
    name: "password-reset___es",
    path: "/password-reset",
    meta: password_45resetSYBE9bXPO1Meta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/password-reset.vue")
  },
  {
    name: "password-reset___en",
    path: "/en/password-reset",
    meta: password_45resetSYBE9bXPO1Meta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/password-reset.vue")
  },
  {
    name: "register___es",
    path: "/register",
    meta: indexHntcHSXaxJMeta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/register/index.vue")
  },
  {
    name: "register___en",
    path: "/en/register",
    meta: indexHntcHSXaxJMeta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/register/index.vue")
  },
  {
    name: "register-password-setup___es",
    path: "/register/password-setup",
    meta: password_45setupl6oVvyzqVeMeta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/register/password-setup.vue")
  },
  {
    name: "register-password-setup___en",
    path: "/en/register/password-setup",
    meta: password_45setupl6oVvyzqVeMeta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/register/password-setup.vue")
  },
  {
    name: "register-profile-setup___es",
    path: "/register/profile-setup",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/register/profile-setup.vue")
  },
  {
    name: "register-profile-setup___en",
    path: "/en/register/profile-setup",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/register/profile-setup.vue")
  },
  {
    name: "subscription-buy___es",
    path: "/subscription/buy",
    meta: buyPmeshfxvzZMeta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/subscription/buy.vue")
  },
  {
    name: "subscription-buy___en",
    path: "/en/subscription/buy",
    meta: buyPmeshfxvzZMeta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/subscription/buy.vue")
  },
  {
    name: "subscription-payment-method___es",
    path: "/subscription/payment-method",
    meta: payment_45methodRv7yUzeyw4Meta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/subscription/payment-method.vue")
  },
  {
    name: "subscription-payment-method___en",
    path: "/en/subscription/payment-method",
    meta: payment_45methodRv7yUzeyw4Meta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/subscription/payment-method.vue")
  },
  {
    name: "subscription-successful___es",
    path: "/subscription/successful",
    meta: successfulYs2ePnf4NNMeta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/subscription/successful.vue")
  },
  {
    name: "subscription-successful___en",
    path: "/en/subscription/successful",
    meta: successfulYs2ePnf4NNMeta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/subscription/successful.vue")
  },
  {
    name: "subscription-update___es",
    path: "/subscription/update",
    meta: updateDR8t5RqW1KMeta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/subscription/update.vue")
  },
  {
    name: "subscription-update___en",
    path: "/en/subscription/update",
    meta: updateDR8t5RqW1KMeta || {},
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/subscription/update.vue")
  },
  {
    name: "users___es",
    path: "/users",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/users/index.vue")
  },
  {
    name: "users___en",
    path: "/en/users",
    component: () => import("/codebuild/output/src3517669114/src/frontend-hire/pages/users/index.vue")
  },
  {
    name: component_45stubTOMzBdaWN8Meta?.name,
    path: "/profile",
    component: component_45stubTOMzBdaWN8
  },
  {
    name: component_45stubTOMzBdaWN8Meta?.name,
    path: "/comprar-suscripcion",
    component: component_45stubTOMzBdaWN8
  },
  {
    name: component_45stubTOMzBdaWN8Meta?.name,
    path: "/offers",
    component: component_45stubTOMzBdaWN8
  }
]